import React from 'react';
import { FormattedMessage } from 'react-intl';
import Lottie from 'react-lottie-player';
import notFoundAnimation from 'src/assets/animations/not-found-animation.json';
import * as style from './NotFound.module.scss';

const NotFound: React.FC = () => {
	return (
		<div className={style.container}>
			<div className={style.image}>
				<Lottie loop animationData={notFoundAnimation} play />
			</div>
			<div className={style.message}>
				<FormattedMessage
					id="notFound.message"
					defaultMessage="The page you are looking for has been abducted."
				/>
			</div>
		</div>
	);
};

export default NotFound;
