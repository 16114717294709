import React from 'react';

import NotFound from 'src/components/notFound/NotFound';
import Header from 'src/components/header/Header';

const NotFoundPage: React.FC = () => {
	return (
		<>
			<Header />
			<NotFound />
		</>
	);
};

export default NotFoundPage;
